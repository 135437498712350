import {number} from "echarts/src/export";
import {questionsSubmit, getVerifyCode, bookingCommit, isPay, addressList} from "./axios";
import {isPhone, isHKPhone, timesStr} from "./utils";

import {Toast} from 'vant'  //vant-UI的提示组件

import dayjs from "dayjs";

export default {
    name: "question1",
    data() {
        return {
            progressNum: 0,
            questionNum: 15,
			pageIndex: 1,
            currentIndex: 0,
            markShowNull: false,
            markShowError: false,
            radio: '',
            result: ['a', 'b'],
            swipeHeight: 0,
            errorTip: '',

            areaList: [
                {text: "中國香港", code: '4401'},
                {text: "中國臺灣", code: '0000'},
                {text: "江蘇省", code: '3200'},
                {text: "上海市", code: '3100'},
                {text: "北京市", code: '1100'},
                {text: "天津市", code: '1200'},
                {text: "浙江省", code: '3300'},
                {text: "廣東省", code: '4400'},
                {text: "遼寧省", code: '2100'},
                {text: "山東省", code: '3700'},
                {text: "內蒙古自治區", code: '1500'},
                {text: "重慶市", code: '5000'},
                {text: "福建省", code: '3500'},
                {text: "海南省", code: '4600'},
                {text: "湖北省", code: '4200'},
                {text: "湖南省", code: '4300'},
                {text: "陝西省", code: '6100'},
                {text: "河南省", code: '4100'},
                {text: "黑龍江省", code: '2300'},
                {text: "青海省", code: '6300'},
                {text: "雲南省", code: '5300'},
                {text: "安徽省", code: '3400'},
                {text: "四川省", code: '5100'},
                {text: "貴州省", code: '5200'},
                {text: "甘肅省", code: '6200'},
                {text: "江西省", code: '3600'},
                {text: "吉林省", code: '2200'},
                {text: "山西省", code: '1400'},
                {text: "河北省", code: '1300'},
                {text: "寧夏回族自治區", code: '6400'},
                {text: "廣西壯族自治區", code: '4500'},
                {text: "新疆維吾爾自治區", code: '6500'},
                {text: "西藏自治區", code: '5400'},
            ],

            height: '',
            weight: '',
            fat: '',
            bust: '',
            waist: '',
            hip: '',
			savedBirth: "",
            area: '',
            drink: '',
            smoking: '',
            catering: '',
            vegetarian: '',
            sports: '',
            sleep: '',
            diseaseList: [
                {"kdis": "K01", "level": "0"},
                {"kdis": "K02", "level": "0"},
                {"kdis": "K03", "level": "0"},
                {"kdis": "K04", "level": "0"},
                {"kdis": "K05", "level": "0"},
                {"kdis": "K06", "level": "0"},
                {"kdis": "K07", "level": "0"},
                {"kdis": "K08", "level": "0"},
                {"kdis": "K09", "level": "0"},
                {"kdis": "K10", "level": "0"},
                {"kdis": "K11", "level": "0"},
                {"kdis": "K12", "level": "0"},
				{"kdis": "K13", "level": "0"},
				{"kdis": "K14", "level": "0"},
				{"kdis": "K15", "level": "0"},
				{"kdis": "K16", "level": "0"},
				{"kdis": "K17", "level": "0"},
				{"kdis": "K18", "level": "0"},
				{"kdis": "K19", "level": "0"}
            ],
            diseaseListAll: [
                {"kdis": "K01", "level": "0", "name": "高血壓"},
                {"kdis": "K02", "level": "0", "name": "高血脂"},
                {"kdis": "K03", "level": "0", "name": "糖尿病"},
                {"kdis": "K04", "level": "0", "name": "慢性肝病"},
                {"kdis": "K05", "level": "0", "name": "慢性腎臟病"},
                {"kdis": "K06", "level": "0", "name": "癌症"},
                {"kdis": "K07", "level": "0", "name": "肝硬化"},
                {"kdis": "K08", "level": "0", "name": "慢性阻塞性肺炎"},
                {"kdis": "K09", "level": "0", "name": "關節炎"},
                {"kdis": "K10", "level": "0", "name": "心臟病"},
                {"kdis": "K11", "level": "0", "name": "腦中風"},
                {"kdis": "K12", "level": "0", "name": "胃腸潰瘍"},
                {"kdis": "K13", "level": "0", "name": "睡眠障礙"},
			// 新增  健康评分
				{"kdis": "K14", "level": "0", "name": "貧血"},
				{"kdis": "K15", "level": "0", "name": "疼痛"},
				{"kdis": "K16", "level": "0", "name": "哮喘"},
				{"kdis": "K17", "level": "0", "name": "痛風"},
				{"kdis": "K18", "level": "0", "name": "痔瘡"},
				{"kdis": "K19", "level": "0", "name": "甲狀腺疾病"},
            ],
            disease: [],
            diseaseArr: [],
            diseaseShow: false,
            noSurgery: [],
            noRelativesDisease: [],
            examineList: [
                {"ne": "WBC", "ve": "", "ut": "A"},
                {"ne": "HGB", "ve": "", "ut": "A"},
                {"ne": "PLT", "ve": "", "ut": "A"},
                {"ne": "FG", "ve": "", "ut": "B"},
                {"ne": "HBA1C", "ve": "", "ut": "A"},
                {"ne": "CHOL", "ve": "", "ut": "B"},
                {"ne": "LDLC", "ve": "", "ut": "B"},
                {"ne": "HDLC", "ve": "", "ut": "B"},
                {"ne": "TG", "ve": "", "ut": "B"},
                {"ne": "GOT", "ve": "", "ut": "A"},
                {"ne": "GPT", "ve": "", "ut": "A"},
                {"ne": "ALP", "ve": "", "ut": "A"},
                {"ne": "ALB", "ve": "", "ut": "B"},
                {"ne": "CRE", "ve": "", "ut": "B"},
                {"ne": "UA", "ve": "", "ut": "B"},
                {"ne": "EGFR", "ve": "", "ut": "A"}
            ],
            // isBooking: JSON.parse(sessionStorage.getItem("isBooking")) || false,
            isBooking: true,
            bookTime: '',
            bookTime2: '',
            pickerShow: false,
            pickerShow2: false,
            bookingArea: '',
            bookingArea2: '',
            showPicker: false,
            showPicker2: false,
            showPicker3: false,
            columns: [
                ],
            currentTime: '',
            currentTime2: '',
            minDate:'',
            maxDate:'',
            phone: '',
            phoneCode: '',
            package: sessionStorage.getItem('package') || 1,
			isYY: 0,//1：跳过预约，直接调用接口。0：填写预约信息 默认为0
			
			sending: false,// 正在发送短信
			submiting: false,// 正在提交
			considerAreaShow: false,
			considerTimeShow: false,
			
			sex: '',//性别
			sexSel: false,//是否弹出性别选择器
			sexList: [ '男', '女' ],
			birth: '',//生日
			currentBirth: new Date('1990/1/1'),//默认显示时间
			birthSel: false,//是否弹出生日选择器
			// birth2: '',
			packageType: '',
			sbp: '',
			dbp: '',
			
			stand: false,
			showPopover: false,
			showPopoverHip: false,
			
			hasD: false,
			
			showPickerA: false,
			area_s: '+852',
			columnsA: [
				{
					text: '+852 中國香港',
					code: '852',
				},
				{
					text: '+86 中國大陸',
					code: '86',
				}
			],
			isSend: false,
        }
    },
    created() {
        this.currentIndex = 0;
        document.title = '調查問卷';
		this.packageType = sessionStorage.getItem('package') ? sessionStorage.getItem('package') : '';
		if(this.packageType != 6) {
			addressList().then( res => {
			    if(res.status == '1'){
			        this.columns = [];
			        res.data.forEach(item => {
			            let address = {};
			            address.id = item.id;
			            address.text = item.address;
			            this.columns.push(address);
			        });
			    }
			});
		}

        let minDate=new Date(new Date());
        minDate.setDate(minDate.getDate()+4);
//如果是前三天就-3
        minDate.getDate();//得到后三天的时间
        this.minDate = minDate;
        this.currentTime = minDate;
        this.currentTime2 = minDate;
        let maxDate=new Date(new Date());
        maxDate.setDate(maxDate.getDate()+14);
//如果是前三天就-3
        maxDate.getDate();//得到后三天的时间
        this.maxDate = maxDate;
		
		
		let questionData = localStorage.getItem('questionData') ? JSON.parse(localStorage.getItem('questionData')) : '';
		
		if(questionData) {
			this.sex = questionData.sex == '1' ? '男' : '女';
			// this.birth = questionData.birth ? questionData.birth : '';
			this.height = questionData.height ? questionData.height : '';
			this.weight = questionData.weight ? questionData.weight : '';
			this.fat = questionData.fat ? questionData.fat : '';
			this.chest = questionData.chest ? questionData.chest : '';
			this.waist = questionData.waist ? questionData.waist : '';
			this.hip = questionData.hip ? questionData.hip : '';
			this.drink = questionData.drink ? questionData.drink + '' : '';
			this.smoking = questionData.smoke ? questionData.smoke + '' : '';
			this.catering = questionData.habeat ? questionData.habeat + '' : '';
			this.vegetarian = questionData.vege ?  questionData.vege + '' : '';
			this.sports = questionData.habsport ? questionData.habsport + '' : '';
			this.sleep = questionData.sleep ? questionData.sleep + '' : '';
			// this.disease = questionData.disease
			this.noSurgery = questionData.oper ? questionData.oper : [];
			this.noRelativesDisease = questionData.famdis ? questionData.famdis : [];
			if (questionData.area && questionData.area.code) {
				this.area = questionData.area ? questionData.area : '';
			} else{
				this.areaList.map(item => {
					if(questionData.area == item.code) {
						this.area = item
					}
				})
			}
			
			this.diseaseList = (questionData.disease && questionData.disease.length > 0) ? questionData.disease : this.diseaseList;
			if(questionData.disease && questionData.disease.length > 0) {
				questionData.disease.map((item, index) => {
					if(item.level != 0) {
						this.disease.push(item.kdis)
					}
				})
			}
		}
		
		
		if (this.packageType == 6) {
		    this.questionNum = 14;
		}else {
			this.questionNum = 15;
		}
		
    },
    mounted() {
        this.jumpQuest();
        this.swipeHeight = document.documentElement.clientHeight - 47;
    },
    methods: {
		formatter(type, val) {
		  if (type === "year") {
		    return val + "年";
		  }
		  if (type === "month") {
		    return val + "月";
		  }
		  if (type === "day") {
		    return val + "日";
		  }
		  return val;
		},
		finish (){// 倒计时结束
			this.isSend = false;
			this.$refs.countDown.reset();// 重置
		},
        onConfirm(value) {
            this.bookingArea = value;
            this.showPicker = false;
        },
        onConfirm2(value) {
            this.bookingArea2 = value;
            this.showPicker2 = false;
        },
        onConfirm3(value) {
            this.area = value;
            this.showPicker3 = false;
        },
		onConfirmA(value) {
			this.area_s = value
			if(value.code == '86') {
				this.area_s = '+86'
			}
			if(value.code == '852') {
				this.area_s = '+852'
			}
            this.showPickerA = false;
		},
        close() {
            this.markShowNull = false;
            this.markShowError = false;
        },
        onClickLeft() {
            this.markShowNull = true;
        },
        backHome() {
            this.$router.push({path: '/'})
        },
        onChange(index) {
            Toast('当前 Swipe 索引：' + index);
        },
        jumpQuest() {
            this.$refs.nextSwipe.swipeTo(this.currentIndex, {
                immediate: true
            });
            this.progressNum = ((this.currentIndex + 1) / this.questionNum) * 100;
			
			
			//切换效果
			this.$nextTick(() => {
				var target = document.querySelectorAll('.van-swipe-item')[this.currentIndex];
				var prevToggle = document.querySelector('.toggle-question');
				prevToggle?prevToggle.classList.remove('toggle-question'):'';
				target.className += ' toggle-question';
			});
        },
        isGo() {
            this.currentIndex++;
            this.jumpQuest()
        },
		accountReg (){
			if(this.phone.trim() == ''){
				Toast.fail('請輸入手機號');
				return false;
			}else if(this.area_s == '+86' && isPhone(this.phone)){
				return true
			}else if(this.area_s == '+852' && isHKPhone(this.phone)){
				return true
			}else{
				Toast.fail('手機號碼格式不正確，請重新輸入');
				return false;
			}
		},//賬號驗證
        sendCode() {
            if(this.accountReg()){
				let data = {
				    account: this.phone,
				    type: 4,
				    loa: isHKPhone(this.phone) ? '00852' : '86'
				};
				this.sending = true;
				getVerifyCode(data).then((res) => {
					this.sending = false;
				    if (res.status == 1) {
						this.isSend = true;
						this.$refs.countDown.start();
				        Toast.success(res.msg);
				    }else{
						Toast.fail(res.msg);
					}
				})
			}
                
        },
        wbc(num) {
            if (num > 150 || num < 0 && num != '') {
                this.tipsMark()
            }
        },
        hgb(num) {
            if (num > 25 || num < 1 && num != '') {
                this.tipsMark()
            }
        },
        plt(num) {
            if (num > 1800 || num < 1 && num != '') {
                this.tipsMark()
            }
        },
        fgChange() {
            if (this.examineList[3].ut == "A") {
                if (this.examineList[3].ve > 700 || this.examineList[3].ve < 30 && this.examineList[3].ve != '') {
                    this.tipsMark()
                }
            } else if (this.examineList[3].ut == "B") {
                if (this.examineList[3].ve > 40 || this.examineList[3].ve < 1.5 && this.examineList[3].ve != '') {
                    this.tipsMark()
                }
            }
        },
        fg(num) {
            if (this.examineList[3].ut == "A") {
                if (num > 700 || num < 30 && num != '') {
                    this.tipsMark()
                }
            } else if (this.examineList[3].ut == "B") {
                if (num > 40 || num < 1.5 && num != '') {
                    this.tipsMark()
                }
            }
        },
        hb1ac(num) {
            if (num > 25 || num < 1 && num != '') {
                this.tipsMark()
            }
        },
		hba1cChange() {
			if (this.examineList[4].ut == "A") {
				this.hba1cPla = '1~25'
			    if (this.examineList[4].ve > 25 || this.examineList[4].ve < 1) {
			        this.tipsMark()
			    }
			} else if (this.examineList[4].ut == "B") {
				this.hba1cPla = '0~10'
			    if (this.examineList[4].ve > 10 || this.examineList[4].ve < 0) {
			        this.tipsMark()
			    }
			}
		},
        cholChange() {
            if (this.examineList[5].ut == "A") {
                if (this.examineList[5].ve > 1100 || this.examineList[5].ve < 50) {
                    this.tipsMark()
                }
            } else if (this.examineList[5].ut == "B") {
                if (this.examineList[5].ve > 28.5 || this.examineList[5].ve < 1.2) {
                    this.tipsMark()
                }
            }
        },
        chol(num) {
            if (this.examineList[5].ut == "A") {
                if (num > 1100 || num < 50 && num != '') {
                    this.tipsMark()
                }
            } else if (this.examineList[5].ut == "B") {
                if (num > 28.5 || num < 1.2 && num != '') {
                    this.tipsMark()
                }
            }
        },
        ldlcChange() {
            if (this.examineList[6].ut == "A") {
                if (this.examineList[6].ve > 650 || this.examineList[6].ve < 1) {
                    this.tipsMark()
                }
            } else if (this.examineList[6].ut == "B") {
                if (this.examineList[6].ve > 17 || this.examineList[6].ve < 0) {
                    this.tipsMark()
                }
            }
        },
        ldlc(num) {
            if (this.examineList[6].ut == "A") {
                if (num > 650 || num < 1 && num != '') {
                    this.tipsMark()
                }
            } else if (this.examineList[6].ut == "B") {
                if (num > 17 || num < 0 && num != '') {
                    this.tipsMark()
                }
            }
        },
        hdlcChange() {
            if (this.examineList[7].ut == "A") {
                if (this.examineList[7].ve > 250 || this.examineList[7].ve < 1) {
                    this.tipsMark()
                }
            } else if (this.examineList[7].ut == "B") {
                if (this.examineList[7].ve > 7 || this.examineList[7].ve < 0) {
                    this.tipsMark()
                }
            }
        },
        hdlc(num) {
            if (this.examineList[7].ut == "A") {
                if (num > 250 || num < 1 && num != '') {
                    this.tipsMark()
                }
            } else if (this.examineList[7].ut == "B") {
                if (num > 7 || num < 0 && num != '') {
                    this.tipsMark()
                }
            }
        },
        tgChange() {
            if (this.examineList[8].ut == "A") {
                if (this.examineList[8].ve > 9000 || this.examineList[8].ve < 1) {
                    this.tipsMark()
                }
            } else if (this.examineList[8].ut == "B") {
                if (this.examineList[8].ve > 110 || this.examineList[8].ve < 0) {
                    this.tipsMark()
                }
            }
        },
        tg(num) {
            if (this.examineList[8].ut == "A") {
                if (num > 9000 || num < 1 && num != '') {
                    this.tipsMark()
                }
            } else if (this.examineList[8].ut == "B") {
                if (num > 110 || num < 0 && num != '') {
                    this.tipsMark()
                }
            }
        },
        got(num) {
            if (num > 2000 || num < 1 && num != '') {
                this.tipsMark()
            }
        },
        gpt(num) {
            if (num > 4000 || num < 1 && num != '') {
                this.tipsMark()
            }
        },
        alp(num) {
            if (num > 6500 || num < 1 && num != '') {
                this.tipsMark()
            }
        },
        albChange() {
            if (this.examineList[12].ut == "A") {
                if (this.examineList[12].ve > 7 || this.examineList[12].ve < 1) {
                    this.tipsMark()
                }
            } else if (this.examineList[12].ut == "B") {
                if (this.examineList[12].ve > 70 || this.examineList[12].ve < 10) {
                    this.tipsMark()
                }
            }
        },
        alb(num) {
            if (this.examineList[12].ut == "A") {
                if (num > 7 || num < 1 && num != '') {
                    this.tipsMark()
                }
            } else if (this.examineList[12].ut == "B") {
                if (num > 70 || num < 10 && num != '') {
                    this.tipsMark()
                }
            }
        },
        creChange() {
            if (this.examineList[13].ut == "A") {
                if (this.examineList[13].ve > 40 || this.examineList[13].ve < 0) {
                    this.tipsMark()
                }
            } else if (this.examineList[13].ut == "B") {
                if (this.examineList[13].ve > 3600 || this.examineList[13].ve < 0) {
                    this.tipsMark()
                }
            }
        },
        cre(num) {
            if (this.examineList[13].ut == "A") {
                if (num > 40 || num < 0 && num != '') {
                    this.tipsMark()
                }
            } else if (this.examineList[13].ut == "B") {
                if (num > 3600 || num < 0 && num != '') {
                    this.tipsMark()
                }
            }
        },
        uaChange() {
            if (this.examineList[14].ut == "A") {
                if (this.examineList[14].ve > 20 || this.examineList[14].ve < 0) {
                    this.tipsMark()
                }
            } else if (this.examineList[14].ut == "B") {
                if (this.examineList[14].ve > 1200 || this.examineList[14].ve < 0) {
                    this.tipsMark()
                }
            }
        },
        ua(num) {
            if (this.examineList[14].ut == "A") {
                if (num > 20 || num < 0 && num != '') {
                    this.tipsMark()
                }
            } else if (this.examineList[14].ut == "B") {
                if (num > 1200 || num < 0 && num != '') {
                    this.tipsMark()
                }
            }
        },
        egfr(num) {
            if (num > 300 || num < 1 && num != '') {
                this.tipsMark()
            }
        },

        nextQuest(isGo) {

            let _that = this;
			
			if(_that.packageType == 6 && isGo != 15) {
				_that.pageIndex = isGo + 2
			}
            // this.isGo();
            if (isGo === 0) {
                if(this.sex == ''){
                	this.markShowError = true;
                	this.errorTip = '請選擇性別'
                }else if(this.birth == '' || this.birth == null){
                	this.markShowError = true;
                	this.errorTip = '請選擇您的出生日期'
                }else{
                	let questionData = localStorage.getItem('questionData') ? JSON.parse(localStorage.getItem('questionData')) : {}
                	let data = {
                		...questionData,
                		sex: this.sex == '男' ? '1' : '2',
                		// birth: new Date(this.birth).getTime() / 1000
                		// birth: this.birth,
						birth: this.savedBirth
                	}
					
                	localStorage.setItem('questionData', JSON.stringify(data))
                	this.isGo()
                }
            } else if (isGo === 1) {
                if (this.height == '' || this.height < 130 || this.height > 200) {
                    this.markShowError = true;
                    this.errorTip = '請輸入合適身高，建議範圍：130~200'
                } else if (this.weight == '' || this.weight < 30 || this.weight > 160) {
                    this.markShowError = true;
                    this.errorTip = '請輸入合適體重，建議範圍：30~160'
                } else if (this.fat != '' && this.fat < 3 || this.fat > 75) {
                    this.markShowError = true;
                    this.errorTip = '請輸入合適體脂率，建議範圍：3~75';
                } else {
					
					let questionData = localStorage.getItem('questionData') ? JSON.parse(localStorage.getItem('questionData')) : {}
					let data = {
						...questionData,
						height: this.height,
						weight: this.weight,
						fat: this.fat,
					}
					
					localStorage.setItem('questionData', JSON.stringify(data))
                    this.isGo()
                }


            } else if (isGo === 2) {

                // if (this.bust == '' || this.bust < 40 || this.bust > 150) {
                //     this.markShowError = true;
                //     this.errorTip = '請輸入合適胸圍，建議範圍：40~150'
                // } else 
				if (this.waist == '' || this.waist < 40 || this.waist > 150) {
                    this.markShowError = true;
                    this.errorTip = '請輸入合適腰圍，建議範圍：40~150'
                } else if (this.hip == '' || this.hip < 60 || this.hip > 165) {
                    this.markShowError = true;
                    this.errorTip = '請輸入合適臀圍，建議範圍：60~165'
                } else {
					let questionData = localStorage.getItem('questionData') ? JSON.parse(localStorage.getItem('questionData')) : {}
					let data = {
						...questionData,
						waist: this.waist,
						hip: this.hip,
					}
					
					localStorage.setItem('questionData', JSON.stringify(data))
                    this.isGo()
                }

            } else if (isGo === 3) {

                if (this.area == '' || typeof area == "number") {
                    this.markShowError = true;
                    this.errorTip = '請選擇您的居住地'
                } else {
					let questionData = localStorage.getItem('questionData') ? JSON.parse(localStorage.getItem('questionData')) : {}
					let data = {
						...questionData,
						area: this.area,
					}
					
					localStorage.setItem('questionData', JSON.stringify(data))
                    this.isGo()
                }

            } else if (isGo === 4) {
                
				this.isGo()
            } else if (isGo === 5) {
               
				this.isGo()
            } else if (isGo === 6) {
                if (this.drink == '') {
                    this.markShowError = true;
                    this.errorTip = '請選擇您是否喝酒'
                } else {
					let questionData = localStorage.getItem('questionData') ? JSON.parse(localStorage.getItem('questionData')) : {}
					let data = {
						...questionData,
						drink: this.drink,
					}
					
					localStorage.setItem('questionData', JSON.stringify(data))
                    this.isGo()
                }
            } else if (isGo === 7) {
                if (this.smoking == '') {
                    this.markShowError = true;
                    this.errorTip = '請選擇您是否吸煙'
                } else {
					let questionData = localStorage.getItem('questionData') ? JSON.parse(localStorage.getItem('questionData')) : {}
					let data = {
						...questionData,
						smoke: this.smoking,
					}
					
					localStorage.setItem('questionData', JSON.stringify(data))
                    this.isGo()
                }

            } else if (isGo === 8) {
                if (this.catering == '') {
                    this.markShowError = true;
                    this.errorTip = '請選擇您的用餐習慣'
                } else {
					let questionData = localStorage.getItem('questionData') ? JSON.parse(localStorage.getItem('questionData')) : {}
					let data = {
						...questionData,
						habeat: this.catering,
					}
					
					localStorage.setItem('questionData', JSON.stringify(data))
                    this.isGo()
                }
            } else if (isGo === 9) {
                if (this.vegetarian == '') {
                    this.markShowError = true;
                    this.errorTip = '請選擇您是否是全日素食者'
                } else {
					let questionData = localStorage.getItem('questionData') ? JSON.parse(localStorage.getItem('questionData')) : {}
					let data = {
						...questionData,
						vege: this.vegetarian,
					}
					localStorage.setItem('questionData', JSON.stringify(data))
                    this.isGo()
                }

            } else if (isGo === 10) {
                if (this.sports == '') {
                    this.markShowError = true;
                    this.errorTip = '請選擇您的運動習慣'
                } else {
					let questionData = localStorage.getItem('questionData') ? JSON.parse(localStorage.getItem('questionData')) : {}
					let data = {
						...questionData,
						habsport: this.sports,
					}
					localStorage.setItem('questionData', JSON.stringify(data))
                    this.isGo()
                }

            } else if (isGo === 11) {
                if (this.sleep == '') {
                    this.markShowError = true;
                    this.errorTip = '請選擇您是否有睡眠障礙'
                } else {
					let questionData = localStorage.getItem('questionData') ? JSON.parse(localStorage.getItem('questionData')) : {}
					let data = {
						...questionData,
						sleep: this.sleep,
					}
					localStorage.setItem('questionData', JSON.stringify(data))
                    this.isGo()
                }

            } else if (isGo === 12) {
                if (this.disease.length > 0) {
					this.hasD = true
                    this.diseaseShow = true
                } else {
					if(this.packageType == 6) {
						this.pageIndex++
					}
					this.hasD = false
                    this.diseaseShow = false
                }
				let questionData = localStorage.getItem('questionData') ? JSON.parse(localStorage.getItem('questionData')) : {}
				let data = {
					...questionData,
					disease: this.diseaseList,
				}
				localStorage.setItem('questionData', JSON.stringify(data))
                this.isGo()
            } else if (isGo === 13) {
                let _that = this;
                try {
                    this.diseaseArr.forEach(function (item, index) {
                        if (item.level == '0') {
                            _that.markShowError = true;
                            _that.errorTip = '請選擇' + item.name + '用藥情况!';
                            throw new Error("EndIterative")
                        } else {
                            _that.diseaseList.forEach(function (itemL, number) {
                                if (item.kdis == itemL.kdis) {
                                    _that.diseaseList[number].level = item.level
                                }
                            });

                        }
                    });
					let questionData = localStorage.getItem('questionData') ? JSON.parse(localStorage.getItem('questionData')) : {}
					let data = {
						...questionData,
						disease: _that.diseaseList,
					}
					localStorage.setItem('questionData', JSON.stringify(data))
                    _that.isGo()
                } catch (e) {
                    console.log('END')
                }

            } else if (isGo === 14) {
				let questionData = localStorage.getItem('questionData') ? JSON.parse(localStorage.getItem('questionData')) : {}
				let data = {
					...questionData,
					oper: this.noSurgery,
				}
				localStorage.setItem('questionData', JSON.stringify(data))
				this.isGo()
            } else if (isGo === 15) {
				let questionData = localStorage.getItem('questionData') ? JSON.parse(localStorage.getItem('questionData')) : {}
				let data = {
					...questionData,
					famdis: this.noRelativesDisease,
				}
				data.goods_id = sessionStorage.getItem('package')
				localStorage.setItem('questionData', JSON.stringify(data))
				data.area = data.area.code
				// if(data.birth) {
				// 	this.markShowError = true;
				// 	this.errorTip = '請選擇您的出生日期'
				// }
				if(this.packageType == 6){
					
					delete data.address
					delete data.time
					delete data.bookTime
					delete data.address2
					delete data.time2
					delete data.bookTime2
					delete data.code
					
					
					questionsSubmit(data).then((res) => {
					    if(res.code == 200){
					        Toast.success("提交問卷成功！");
							
							sessionStorage.setItem('orderid', res.data.id)
							
							this.$router.replace({
								path: '/payment/0'
							})
					        // this.$router.replace({path: '/user/history'});
					    }else {
					        Toast.fail(res.msg);
					    }  
					})
					
				} else {
					this.isGo()
				}
				
            } else if (isGo === 16) {
                if (this.examineList[0].ve == '') {
                    this.markShowError = true;
                    this.errorTip = '請填寫信息!'
                } else if (this.examineList[1].ve == '') {
                    this.markShowError = true;
                    this.errorTip = '請填寫信息!'
                } else if (this.examineList[2].ve == '') {
                    this.markShowError = true;
                    this.errorTip = '請填寫信息!'
                } else {
					let questionData = JSON.parse(localStorage.getItem('questionData'))
					let data = {
						...questionData,
						examineList: this.examineList,
						sbp: this.sbp,
						dbp: this.dbp,
					}
					localStorage.setItem('questionData', JSON.stringify(data))
                    this.isGo()
                }
            
            
            } else if (isGo === 17) {
                if (this.examineList[3].ve == '') {
                    this.markShowError = true;
                    this.errorTip = '請填寫信息!'
                } else if (this.examineList[4].ve == '') {
                    this.markShowError = true;
                    this.errorTip = '請填寫信息!'
                } else {
					let questionData = JSON.parse(localStorage.getItem('questionData'))
					let data = {
						...questionData,
						examineList: this.examineList,
					}
					localStorage.setItem('questionData', JSON.stringify(data))
                    this.isGo()
                }
            
            } else if (isGo === 18) {
                if (this.examineList[5].ve == '') {
                    this.markShowError = true;
                    this.errorTip = '請填寫信息!'
                } else if (this.examineList[6].ve == '') {
                    this.markShowError = true;
                    this.errorTip = '請填寫信息!'
                } else if (this.examineList[7].ve == '') {
                    this.markShowError = true;
                    this.errorTip = '請填寫信息!'
                } else if (this.examineList[8].ve == '') {
                    this.markShowError = true;
                    this.errorTip = '請填寫信息!'
                } else {
					let questionData = JSON.parse(localStorage.getItem('questionData'))
					let data = {
						...questionData,
						examineList: this.examineList,
					}
					localStorage.setItem('questionData', JSON.stringify(data))
                    this.isGo()
                }
            
            } else if (isGo === 19) {
                if (this.examineList[9].ve == '') {
                    this.markShowError = true;
                    this.errorTip = '請填寫信息!'
                } else if (this.examineList[10].ve == '') {
                    this.markShowError = true;
                    this.errorTip = '請填寫信息!'
                } else if (this.examineList[11].ve == '') {
                    this.markShowError = true;
                    this.errorTip = '請填寫信息!'
                } else if (this.examineList[12].ve == '') {
                    this.markShowError = true;
                    this.errorTip = '請填寫信息!'
                } else {
					let questionData = JSON.parse(localStorage.getItem('questionData'))
					let data = {
						...questionData,
						examineList: this.examineList,
					}
					localStorage.setItem('questionData', JSON.stringify(data))
                    this.isGo()
                }
            } else if (isGo === 20) {
                if (this.examineList[13].ve == '') {
                    this.markShowError = true;
                    this.errorTip = '請填寫信息!'
                } else if (this.examineList[14].ve == '') {
                    this.markShowError = true;
                    this.errorTip = '請填寫信息!'
                } else {
					let questionData = JSON.parse(localStorage.getItem('questionData'))
					let data = {
						...questionData,
						examineList: this.examineList,
						examine: this.examineList,
					}
					localStorage.setItem('questionData', JSON.stringify(data))
                   
                    data.area = data.area.code
                    data.goods_id = sessionStorage.getItem('package')
                    questionsSubmit(data).then((res) => {
                        if(res.code == 200){
                            Toast.success("提交問卷成功！");
							sessionStorage.setItem('orderid', res.data.id)
							this.$router.replace({
								path: '/payment/0'
							})
                            // this.$router.replace({path:'/user/history'});
                        }else {
                            Toast.fail(res.msg);
                        }
            
                    })
                }
            } else if (isGo === 21) {
                if (this.bookingArea == '') {
                    Toast.fail("請選擇體檢地點！")
                } else if (this.bookTime == '') {
                    Toast.fail("請選擇體檢日期與時間")
                } else if (!this.accountReg()) {
                    
                } else if (this.phoneCode == '') {
                    Toast.fail("驗證碼不能為空！")
                } else {
                	let questionData = JSON.parse(localStorage.getItem('questionData'))
                	let data = {
                		...questionData,
                		address: this.bookingArea.id,
                		// time: (new Date(this.currentTime)).getTime() / 1000,
                		time: this.bookTime,
                		address2: this.bookingArea2.id,
                		// time2: (new Date(this.currentTime2)).getTime() / 1000,
                		time2: this.bookTime2,
                		mobile: this.phone,
                		code: this.phoneCode
                	}
                	localStorage.setItem('questionData', JSON.stringify(data))
                	this.submiting = true;
					
					data.area = data.area.code
					data.goods_id = sessionStorage.getItem('package')
                    questionsSubmit(data).then((res) => {
                    	this.submiting = false;
                        if(res.code == 200){
                            Toast.success(res.msg);
							sessionStorage.setItem('orderid', res.data.id)
							this.$router.replace({
								path: '/payment/0'
							})
                            // this.$router.replace('/user/history')
                        }else{
                    		Toast.fail(res.msg);
                    	}
                    })
                }
            }
        },
		submitData() {
			questionsSubmit(questionData).then((res) => {
				this.submiting = false;
			    if(res.code == 200){
			        Toast.success(res.msg);
					sessionStorage.setItem('orderid', res.data.id)
					this.$router.replace({
						path: '/payment/0'
					})
			        // this.$router.replace('/user/history')
			    }else{
					Toast.fail(res.msg);
				}
			})
		},
        prevQuest() {
            this.currentIndex--;
			if(this.packageType == 6) {
				if(this.hasD){
					this.pageIndex--
				} else if(!this.hasD && this.pageIndex == 15) {
					this.pageIndex = this.pageIndex - 2
				} else { 
					this.pageIndex--
				}
			}
			
            this.jumpQuest()
        },
        tipsMark() {
            this.markShowError = true;
            this.errorTip = "<p>您填寫的結果可能存在較大偏差，請您仔細核對該數值和所用單位是否正確並與您的報告一致。</p><br/><p>若您已經核對確認，並堅持以該結果進行提交和評估，我們可能會根據大數據算法對該結果進行一定程度上的修正和處理，以提供更合理的大數據評估結果和建議。</p>"
        },
        confirm(value) {
            // if(new Date(this.currentTime).getDay() == '0'){
            //     Toast.fail("請選擇週日以外的日期!");
            // }else {
            //     this.pickerShow = false;
            //     let dateFormat = (new Date(this.currentTime)).getTime();
            //     this.bookTime = new Date(dateFormat).toLocaleString().replace(/:\d{1,2}$/, ' ');
            // }
			var language = navigator.language;
			if (language.toLocaleLowerCase().indexOf("zh-cn") >= 0) {
			  this.bookTime = dayjs(value).format("YYYY/MM/DD");
			} else {
			  this.bookTime = dayjs(value).format("YYYY/MM/DD");
			}
			this.bookTime = dayjs(value).format("YYYY/MM/DD");
			
			this.pickerShow = false;
        },
        confirm2(value) {
            // if(new Date(this.currentTime2).getDay() == '0'){
            //     Toast.fail("請選擇週日以外的日期!");
            // } else {
            //     this.pickerShow2 = false;
            //     let dateFormat = (new Date(this.currentTime2)).getTime();
            //     this.bookTime2 = new Date(dateFormat).toLocaleString().replace(/:\d{1,2}$/, ' ');
            // }
			var language = navigator.language;
			if (language.toLocaleLowerCase().indexOf("zh-cn") >= 0) {
			  this.bookTime2 = dayjs(value).format("YYYY/MM/DD");
			} else {
			  this.bookTime2 = dayjs(value).format("YYYY/MM/DD");
			}
			this.bookTime2 = dayjs(value).format("YYYY/MM/DD");
			
			this.pickerShow2 = false;
        },
        // filter(type, options) {
        //     if (type === 'minute') {
        //         return options.filter(option => option % 15 === 0)
        //     }
        //     if (type === 'hour') {
        //         return options.filter(option => option < 13 && option > 8)
        //     }
        //     return options;
        // },
		
		//性别选择
		sexConfirm(value) {
			this.sex = value;
			this.sexSel = false;
		},
		//生日选择A
		birthConfirm(value) {
			// this.birth = value.toLocaleDateString('zh-CN');
			// this.dataStr(date)
			// this.birth = '16/12/1961'
			// this.birthSel = false;
			
			var language = navigator.language;
			if (language.toLocaleLowerCase().indexOf("zh-cn") >= 0) {
			  this.birth = dayjs(value).format("YYYY/MM/DD");
			} else {
			  this.birth = dayjs(value).format("YYYY/MM/DD");
			}
			this.savedBirth = dayjs(value).format("YYYY/MM/DD");
			
			this.birthSel = false;
		},
		dataStr(date){
			let year = date.getFullYear();
			let month = ("0" + (date.getMonth() + 1)).slice(-2);
			let day = ("0" + date.getDate()).slice(-2);
			let formattedDate = year + "/" + month + "/" + day;
			this.birth = formattedDate
		},
		limitDate(year) {//参数：距今多少年
			let now = new Date();
			return new Date((now.getFullYear()-year)+'/'+(now.getMonth()+1)+'/'+now.getDate());
		}
    },
	
	computed: {
		//年龄为20-90岁之间(直接绑定在组件上会卡死)
		birthMinDate (){
			return this.limitDate(90);
		},
		birthMaxDate (){
			return this.limitDate(20);
		}
	},
    watch: {
        disease(newVal, oldVal) {
            if (newVal.length > 0) {
                let _that = this;
                _that.diseaseArr = [];
                this.disease.forEach(function (item, index) {
                    _that.diseaseListAll.forEach(function (itemL, number) {
                        if (item == itemL.kdis) {
                            _that.diseaseArr.push(_that.diseaseListAll[number]);
                        }
                    })
                });
				
				_that.diseaseList.forEach(function (item, index) {
					_that.diseaseArr.forEach(function (itemL, number) {
					    if (item.kdis == itemL.kdis) {
					        itemL.level = item.level
					    }
					})
				})
				
                this.questionNum = 15;
            } else {
				this.diseaseList = [
						{"kdis": "K01", "level": "0"},
						{"kdis": "K02", "level": "0"},
						{"kdis": "K03", "level": "0"},
						{"kdis": "K04", "level": "0"},
						{"kdis": "K05", "level": "0"},
						{"kdis": "K06", "level": "0"},
						{"kdis": "K07", "level": "0"},
						{"kdis": "K08", "level": "0"},
						{"kdis": "K09", "level": "0"},
						{"kdis": "K10", "level": "0"},
						{"kdis": "K11", "level": "0"},
						{"kdis": "K12", "level": "0"},
						{"kdis": "K13", "level": "0"},
						{"kdis": "K14", "level": "0"},
						{"kdis": "K15", "level": "0"},
						{"kdis": "K16", "level": "0"},
						{"kdis": "K17", "level": "0"},
						{"kdis": "K18", "level": "0"},
						{"kdis": "K19", "level": "0"}
					]
                this.questionNum = 15;
            }
        }
    }
}
