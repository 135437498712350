<template>
  <div class="content mirror_wrap">
    <van-nav-bar
      title="調查問卷"
      left-arrow
      @click-left="onClickLeft"
      :fixed="true"
    />
    <van-progress
      color="#6681FA"
      :percentage="progressNum"
      :show-pivot="false"
      pivot-text=""
      stroke-width="1"
      track-color="#DCDEE5"
    />
    <!-- <div class="page_box" v-if="packageType == 6">
			<span>{{pageIndex}}</span>/16
		</div> -->
    <van-swipe
      :loop="false"
      :show-indicators="true"
      :touchable="false"
      ref="nextSwipe"
      :initial-swipe="currentIndex"
      :height="swipeHeight"
    >
      <van-swipe-item>
        <div class="swipeBox" ref="scrollRef1">
          <div class="swiperScroll">
            <div class="question">
              <div class="avatar"></div>
              <div class="quesText">
                您好，請告訴我您的性別和出生日期？
              </div>
              <div class="clear"></div>
            </div>
            <div class="reply">
              <div class="sex group">
                <span>性別</span>
                <div class="groupLabel">
                  <div class="arrow"></div>
                  <van-field
                    readonly
                    clickable
                    :value="sex"
                    placeholder="請選擇"
                    @click="sexSel = true"
                  />
                </div>
              </div>
              <div class="group">
                <span>出生日期</span>
                <div class="groupLabel">
                  <div class="arrow"></div>
                  <van-field
                    readonly
                    clickable
                    :value="birth"
                    placeholder="請選擇"
                    @click="birthSel = true"
                  />
                </div>
              </div>
              <div class="group fitText">
                米因大數據評估僅適用於年齡在20~90周歲之間人群。
              </div>
            </div>
            <div class="nextQues" @click="nextQuest(0)">下一步</div>
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="swipeBox" ref="scrollRef2">
          <div class="swiperScroll">
            <div class="question">
              <div class="avatar"></div>
              <div class="quesText">
                您好，請告訴我您的身高、體重和體脂率？
              </div>
              <div class="clear"></div>
            </div>
            <div class="reply">
              <div class="group">
                <span>身高</span>
                <div class="groupLabel">
                  <label>
                    <van-field
                      v-model="height"
                      placeholder="請填寫"
                      type="number"
                    />
                    <div class="unit"><span>cm</span></div>
                  </label>
                </div>
              </div>
              <div class="group">
                <span>體重</span>
                <div class="groupLabel">
                  <label>
                    <van-field
                      v-model="weight"
                      placeholder="請填寫"
                      type="number"
                    />
                    <div class="unit"><span>kg</span></div>
                  </label>
                </div>
              </div>
              <div class="group">
                <span>體脂率</span>
                <div class="groupLabel">
                  <label>
                    <van-field
                      v-model="fat"
                      placeholder="請填寫(選填)"
                      type="number"
                    />
                    <div class="unit"><span>%</span></div>
                  </label>
                </div>
              </div>
              <div class="group fitText">
                若您不知道自身的體脂率信息，米因大數據會為您進行推算。
                若您屬於健身愛好者，運動員等體脂率較為特殊的人群，請您務必填寫準確的體脂率數值。
              </div>
            </div>
            <div class="prevQues" @click="prevQuest">上一步</div>
            <div class="nextQues" @click="nextQuest(1)">下一步</div>
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="swipeBox waist_box" ref="scrollRef3">
          <div class="swiperScroll">
            <div class="question">
              <div class="avatar"></div>
              <div class="quesText">
                請告訴我您的腰臀圍信息？
              </div>
              <div class="clear"></div>
            </div>
            <div class="reply">
              <div class="group">
                <div class="group_title_cell">
                  <span>腰圍</span>
                  <span class="cankao" @click="showPopover = true"
                    >參考腰圍</span
                  >
                </div>
                <div class="groupLabel">
                  <label>
                    <van-field
                      v-model="waist"
                      placeholder="請填寫"
                      type="number"
                    />
                    <div class="unit"><span>cm</span></div>
                  </label>
                </div>
              </div>
              <div class="group">
                <div class="group_title_cell">
                  <span>臀圍</span>
                  <span class="cankao" @click="showPopoverHip = true"
                    >參考臀圍</span
                  >
                </div>
                <div class="groupLabel">
                  <label>
                    <van-field
                      v-model="hip"
                      placeholder="請填寫"
                      type="number"
                    />
                    <div class="unit"><span>cm</span></div>
                  </label>
                </div>
              </div>
            </div>
            <div class="prevQues" @click="prevQuest">上一步</div>
            <div class="nextQues" @click="nextQuest(2)">下一步</div>

            <div class="question1 new_question">
              <div class="avatar"></div>
              <div class="quesText">
                <img
                  class="question_tips_icon"
                  src="@/assets/images/question_tips.png"
                  alt=""
                />
                隨年齡增長，BMI與四高、長期慢病及重症，有非常緊密的聯系。
                而四高風險對個人免疫力有非常大的影響，所以，要降低四高風險，首先應加強管理體型、體重。
              </div>
              <div class="clear"></div>
            </div>
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="swipeBox" ref="scrollRef4">
          <div class="swiperScroll">
            <div class="question">
              <div class="avatar"></div>
              <div class="quesText">
                請選擇您的居住地？
              </div>
              <div class="clear"></div>
            </div>
            <div class="reply">
              <div class="group">
                <div class="groupLabel selArea">
                  <div class="arrow"></div>
                  <van-field
                    readonly
                    clickable
                    :value="area.text"
                    placeholder="請選擇"
                    @click="showPicker3 = true"
                  />
                </div>
              </div>
            </div>
            <div class="prevQues" @click="prevQuest">上一步</div>
            <div class="nextQues" @click="nextQuest(3)">下一步</div>
          </div>
        </div>
      </van-swipe-item>
      <!-- 4、5 -->
      <van-swipe-item>
        <div class="swipeBox" ref="scrollRef5">
          <div class="swiperScroll">
            <div class="question">
              <div class="avatar"></div>
              <div class="quesText">
                您是否喝酒？
              </div>
              <div class="clear"></div>
            </div>
            <div class="reply">
              <van-radio-group v-model="drink">
                <van-radio checked-color="#6883FB" name="1">不喝</van-radio>
                <van-radio checked-color="#6883FB" name="2">天天喝</van-radio>
                <van-radio checked-color="#6883FB" name="3">經常喝</van-radio>
                <van-radio checked-color="#6883FB" name="4">偶爾喝</van-radio>
              </van-radio-group>
            </div>
            <div class="prevQues" @click="prevQuest">上一步</div>
            <div class="nextQues" @click="nextQuest(6)">下一步</div>

            <div class="question1 new_question">
              <div class="avatar"></div>
              <div class="quesText">
                <img
                  class="question_tips_icon"
                  src="@/assets/images/question_tips.png"
                  alt=""
                />
                喝酒習慣：指的是所有含酒精飲品或食品。
              </div>
              <div class="clear"></div>
            </div>
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="swipeBox" ref="scrollRef6">
          <div class="swiperScroll">
            <div class="question">
              <div class="avatar"></div>
              <div class="quesText">
                您是否吸煙？
              </div>
              <div class="clear"></div>
            </div>
            <div class="reply">
              <van-radio-group v-model="smoking">
                <van-radio checked-color="#6883FB" name="1">不吸</van-radio>
                <van-radio checked-color="#6883FB" name="2">天天吸</van-radio>
                <van-radio checked-color="#6883FB" name="3">經常吸</van-radio>
                <van-radio checked-color="#6883FB" name="4">偶爾吸</van-radio>
              </van-radio-group>
            </div>
            <div class="prevQues" @click="prevQuest">上一步</div>
            <div class="nextQues" @click="nextQuest(7)">下一步</div>

            <div class="question1 new_question">
              <div class="avatar"></div>
              <div class="quesText">
                <img
                  class="question_tips_icon"
                  src="@/assets/images/question_tips.png"
                  alt=""
                />
                吸煙習慣：指的是所有傳統香煙、雪茄、水煙和所有電子煙產品。18個月內，即使只吸一支煙的，也算作「偶爾吸煙」。
              </div>
              <div class="clear"></div>
            </div>
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="swipeBox" ref="scrollRef7">
          <div class="swiperScroll">
            <div class="question">
              <div class="avatar"></div>
              <div class="quesText">
                您的用餐習慣？
              </div>
              <div class="clear"></div>
            </div>
            <div class="reply">
              <van-radio-group v-model="catering">
                <van-radio checked-color="#6883FB" name="1">定時定量</van-radio>
                <van-radio checked-color="#6883FB" name="2"
                  >不定時不定量</van-radio
                >
                <van-radio checked-color="#6883FB" name="3"
                  >定時但不定量</van-radio
                >
                <van-radio checked-color="#6883FB" name="4"
                  >不定時但定量</van-radio
                >
              </van-radio-group>
            </div>
            <div class="prevQues" @click="prevQuest">上一步</div>
            <div class="nextQues" @click="nextQuest(8)">下一步</div>
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="swipeBox" ref="scrollRef8">
          <div class="swiperScroll">
            <div class="question">
              <div class="avatar"></div>
              <div class="quesText">
                您是否是全日素食者？
              </div>
              <div class="clear"></div>
            </div>
            <div class="reply">
              <van-radio-group v-model="vegetarian">
                <van-radio checked-color="#6883FB" name="1">不是</van-radio>
                <van-radio checked-color="#6883FB" name="2">是</van-radio>
              </van-radio-group>
            </div>
            <div class="prevQues" @click="prevQuest">上一步</div>
            <div class="nextQues" @click="nextQuest(9)">下一步</div>
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="swipeBox" ref="scrollRef9">
          <div class="swiperScroll">
            <div class="question">
              <div class="avatar"></div>
              <div class="quesText">
                您的運動習慣？
              </div>
              <div class="clear"></div>
            </div>
            <div class="reply">
              <van-radio-group v-model="sports">
                <van-radio checked-color="#6883FB" name="1"
                  >沒有或每週少於1小時</van-radio
                >
                <van-radio checked-color="#6883FB" name="2"
                  >每週運動1～2小時</van-radio
                >
                <van-radio checked-color="#6883FB" name="3"
                  >每週運動3～4小時</van-radio
                >
                <van-radio checked-color="#6883FB" name="4"
                  >每週運動5～6小時</van-radio
                >
                <van-radio checked-color="#6883FB" name="5"
                  >每週運動7小時以上</van-radio
                >
              </van-radio-group>
            </div>
            <div class="prevQues" @click="prevQuest">上一步</div>
            <div class="nextQues" @click="nextQuest(10)">下一步</div>

            <div class="question1 new_question">
              <div class="avatar"></div>
              <div class="quesText">
                <img
                  class="question_tips_icon"
                  src="@/assets/images/question_tips.png"
                  alt=""
                />
                問卷中的運動習慣時間：連續運動半小時以上才能定義為運動中（做家務不算運動哦!），運動類型和強度根據年齡和身體狀況而定。
              </div>
              <div class="clear"></div>
            </div>
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="swipeBox sleep_box" ref="scrollRef10">
          <div class="swiperScroll">
            <div class="question">
              <div class="avatar"></div>
              <div class="quesText">
                您是否有睡眠障礙？
              </div>
              <div class="clear"></div>
            </div>
            <div class="reply">
              <van-radio-group v-model="sleep">
                <van-radio checked-color="#6883FB" name="1">沒有</van-radio>
                <van-radio checked-color="#6883FB" name="2">非常嚴重</van-radio>
                <van-radio checked-color="#6883FB" name="3">經常</van-radio>
                <van-radio checked-color="#6883FB" name="4">偶爾</van-radio>
              </van-radio-group>
              <div class="group fitText">
                睡眠障礙的表現包括：睡眠時間過長或過短，如入睡困難、淺睡、易醒、失眠等；睡眠過程中出現異常行為，如夢遊、夢囈、夜驚、磨牙等
              </div>
            </div>
            <div class="prevQues" @click="prevQuest">上一步</div>
            <div class="nextQues" @click="nextQuest(11)">下一步</div>
            <!-- <div class="question1 new_question">
						    <div class="avatar"></div>
						    <div class="quesText">
								<img class="question_tips_icon" src="@/assets/images/question_tips.png" alt="">
						         長期失眠得不到改善，將造成內分泌失調，導致免疫力下降。 若失眠狀況在一周內超過三天，並且持續兩到三周以上，建議及早就醫。
						    </div>
						    <div class="clear"></div>
						</div> -->

            <div class="question1 new_question">
              <div class="avatar"></div>
              <div class="quesText">
                <img
                  class="question_tips_icon"
                  src="@/assets/images/question_tips.png"
                  alt=""
                />
                問卷中的睡眠障礙的指標：
                需要服用藥物幫助才能正常入睡的，屬於睡眠障礙。不需要服用藥物幫助睡眠的，請選擇：沒有；需嚴重藥物幫助睡眠的，請選擇：非常嚴重；經常服用藥物幫助睡眠的，請選擇：經常；
                偶爾服用藥物幫助睡眠的，請選擇：偶爾。
              </div>
              <div class="clear"></div>
            </div>
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="swipeBox" ref="scrollRef11">
          <div class="swiperScroll">
            <div class="question">
              <div class="avatar"></div>
              <div class="quesText">
                您患有過下列疾病嗎？（<span style="color: #6681FA;">可多選</span
                >），全部沒有的話，直接點擊"下一步"即可。
              </div>
              <div class="clear"></div>
            </div>
            <div class="reply">
              <van-checkbox-group v-model="disease" checked-color="#6883FB">
                <van-checkbox
                  checked-color="#6883FB"
                  shape="square"
                  v-for="(disItem, index) in diseaseListAll"
                  :name="disItem.kdis"
                  :key="index"
                  >{{ disItem.name }}
                </van-checkbox>
              </van-checkbox-group>
            </div>
            <div class="prevQues" @click="prevQuest">上一步</div>
            <div class="nextQues" @click="nextQuest(12)">下一步</div>

            <div class="question1 new_question">
              <div class="avatar"></div>
              <div class="quesText">
                <img
                  class="question_tips_icon"
                  src="@/assets/images/question_tips.png"
                  alt=""
                />
                問卷中的患病指的是：
                醫生經過對病人疾病的病因、發病機製作出分類鑑別，最後確診的疾病。
                換句話說，自己覺得感覺不舒服但是沒有正規醫生診斷的，不能算作「患病」。
              </div>
              <div class="clear"></div>
            </div>
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item v-if="diseaseShow">
        <div class="swipeBox medicine" ref="scrollRef12">
          <div class="swiperScroll">
            <div class="question">
              <div class="avatar"></div>
              <div class="quesText">
                請選擇患病的用藥情況 (每種疾病請選擇壹種用藥情況)
              </div>
              <div class="clear"></div>
            </div>
            <div class="reply">
              <table id="selTable" cellspacing="0">
                <thead>
                  <tr>
                    <td>疾病名稱</td>
                    <td>不服藥</td>
                    <td>偶爾服藥</td>
                    <td>經常服藥</td>
                  </tr>
                  <tr v-for="(disItemA, indexO) in diseaseArr" :key="indexO">
                    <td>{{ disItemA.name }}</td>
                    <td>
                      <label
                        ><input
                          type="radio"
                          v-model="disItemA.level"
                          :name="disItemA.kdis"
                          value="3"/><span></span
                      ></label>
                    </td>
                    <td>
                      <label
                        ><input
                          type="radio"
                          v-model="disItemA.level"
                          :name="disItemA.kdis"
                          value="2"/><span></span
                      ></label>
                    </td>
                    <td>
                      <label
                        ><input
                          type="radio"
                          v-model="disItemA.level"
                          :name="disItemA.kdis"
                          value="1"/><span></span
                      ></label>
                    </td>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
            <div class="prevQues" @click="prevQuest">上一步</div>
            <div class="nextQues" @click="nextQuest(13)">下一步</div>
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="swipeBox" ref="scrollRef13">
          <div class="swiperScroll">
            <div class="question">
              <div class="avatar"></div>
              <div class="quesText">
                您是否做過下列手術？（<span style="color: #6681FA;">可多選</span
                >），全部沒有的話，直接點擊"下一步"即可。
              </div>
              <div class="clear"></div>
            </div>
            <div class="reply">
              <van-checkbox-group v-model="noSurgery">
                <van-checkbox checked-color="#6883FB" shape="square" name="1"
                  >頭或腦部</van-checkbox
                >
                <van-checkbox checked-color="#6883FB" shape="square" name="2"
                  >眼鼻耳</van-checkbox
                >
                <van-checkbox checked-color="#6883FB" shape="square" name="3"
                  >口腔、食道</van-checkbox
                >
                <van-checkbox checked-color="#6883FB" shape="square" name="4"
                  >胸部(心、肺等)</van-checkbox
                >
                <van-checkbox checked-color="#6883FB" shape="square" name="5"
                  >腹部(胃、肝、膽、胰、腎等)</van-checkbox
                >
                <van-checkbox checked-color="#6883FB" shape="square" name="6"
                  >關節與骨</van-checkbox
                >
                <van-checkbox checked-color="#6883FB" shape="square" name="7"
                  >婦科手術</van-checkbox
                >
                <van-checkbox checked-color="#6883FB" shape="square" name="8"
                  >泌尿系統</van-checkbox
                >
              </van-checkbox-group>
            </div>
            <div class="prevQues" @click="prevQuest">上一步</div>
            <div class="nextQues" @click="nextQuest(14)">下一步</div>
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="swipeBox" ref="scrollRef14">
          <div class="swiperScroll">
            <div class="question">
              <div class="avatar"></div>
              <div class="quesText">
                您的直系親屬有下列疾病嗎？（<span style="color: #6681FA;"
                  >可多選</span
                >），全部沒有的話，直接點擊"{{
                  packageType == 6 ? "提交" : "選好了"
                }}"即可。
              </div>
              <div class="clear"></div>
            </div>
            <div class="reply">
              <van-checkbox-group v-model="noRelativesDisease">
                <van-checkbox checked-color="#6883FB" shape="square" name="1"
                  >癌症</van-checkbox
                >
                <van-checkbox checked-color="#6883FB" shape="square" name="2"
                  >腦中風</van-checkbox
                >
                <van-checkbox checked-color="#6883FB" shape="square" name="3"
                  >心肌梗塞</van-checkbox
                >
                <van-checkbox checked-color="#6883FB" shape="square" name="4"
                  >肝硬化</van-checkbox
                >
                <van-checkbox checked-color="#6883FB" shape="square" name="5"
                  >老年失智</van-checkbox
                >
                <van-checkbox checked-color="#6883FB" shape="square" name="6"
                  >慢性阻塞性肺炎</van-checkbox
                >
                <van-checkbox checked-color="#6883FB" shape="square" name="7"
                  >結核病</van-checkbox
                >
                <van-checkbox checked-color="#6883FB" shape="square" name="8"
                  >先天畸形</van-checkbox
                >
              </van-checkbox-group>
            </div>
            <div class="prevQues" @click="prevQuest">上一步</div>
            <div class="nextQues" @click="nextQuest(15)">
              {{ packageType == 6 ? "提交" : "選好了" }}
            </div>

            <div class="question1 new_question">
              <div class="avatar"></div>
              <div class="quesText">
                <img
                  class="question_tips_icon"
                  src="@/assets/images/question_tips.png"
                  alt=""
                />
                問卷中的直系親屬包括：
                父母、子女、祖父母、外祖父母，兄弟姐妹、子女、孫子女等。
              </div>
              <div class="clear"></div>
            </div>
          </div>
        </div>
      </van-swipe-item>
      <!-- <van-swipe-item v-if="blt == 2"> 
                 <div class="swipeBox"ref="scrollRef15"> 
                     <div class="swiperScroll"  > 
                         <div class="question"> 
                             <div class="avatar"></div> 
                             <div class="quesText"> 
                                 請填寫您的血液常規檢查结果 
                             </div> 
                             <div class="clear"></div> 
                         </div> 
                         <div class="reply"> 
							 <div class="group">
							     <span>收縮壓</span>
							     <div class="groupLabel">
							         <label>
							             <van-field v-model="sbp" placeholder="請填寫" type="number"/>
							             <div class="unit" style="line-height: 0.65rem">
							                 <span>mmHg</span></div>
							         </label>
							     </div>
							 </div>
							 <div class="group">
							     <span>舒張壓</span>
							     <div class="groupLabel">
							         <label>
							             <van-field v-model="dbp" placeholder="請填寫" type="number"/>
							             <div class="unit"><span>mmHg</span></div>
							         </label>
							     </div>
							 </div>
                             <div class="group"> 
                                 <span>白血球(WBC)</span> 
                                 <div class="groupLabel"> 
                                     <label> 
                                         <van-field v-model="examineList[0].ve" placeholder="請填寫" 
                                                    @blur="wbc(examineList[0].ve)" type="number"/> 
                                         <div class="unit" style="line-height: 0.65rem"> 
                                             <span>10<sup>3</sup>(10<sup>9</sup>L)</span></div> 
                                     </label> 
                                 </div> 
                             </div> 
                             <div class="group"> 
                                 <span>血紅素(HGB)</span> 
                                 <div class="groupLabel"> 
                                     <label> 
                                         <van-field v-model="examineList[1].ve" placeholder="請填寫" 
                                                    @blur="hgb(examineList[1].ve)" type="number"/> 
                                         <div class="unit"><span>g/dL</span></div> 
                                     </label> 
                                 </div> 
                             </div> 
                             <div class="group"> 
                                 <span>血小板(PLT)</span> 
                                 <div class="groupLabel"> 
                                     <label> 
                                         <van-field v-model="examineList[2].ve" placeholder="請填寫" 
                                                    @blur="plt(examineList[2].ve)" type="number"/> 
                                         <div class="unit" style="line-height: 0.65rem"> 
                                             <span>10<sup>3</sup>(10<sup>9</sup>L)</span></div> 
                                     </label> 
                                 </div> 
                             </div> 
                         </div> 
                         <div class="nextQues" @click="nextQuest(16)">填好了</div> 
                         <div class="prevQues" @click="prevQuest"></div> 
                     </div> 
                 </div> 
             </van-swipe-item> 
             <van-swipe-item v-if="blt == 2"> 
                 <div class="swipeBox " ref="scrollRef16"> 
                     <div class="swiperScroll" > 
                         <div class="question"> 
                             <div class="avatar"></div> 
                             <div class="quesText"> 
                                 請填寫您的血糖檢查结果 
                             </div> 
                             <div class="clear"></div> 
                         </div> 
                         <div class="reply"> 
                             <div class="group"> 
                                 <span>空腹血糖(FG)</span> 
                                 <div class="groupLabel"> 
                                     <label> 
                                         <van-field v-model="examineList[3].ve" placeholder="請填寫" 
                                                    @blur="fg(examineList[3].ve)" type="number"/> 
                                         <div class="unit"> 
                                             <label style="margin-right: 0.32rem;"> <input v-model="examineList[3].ut" 
                                                                                           value="B" type="radio" 
                                                                                           name="fg" 
                                                                                           @change="fgChange"> 
                                                 <p></p> <span>mmol/mol</span> 
                                             </label> 
                                             <label> <input v-model="examineList[3].ut" value="A" 
                                                            type="radio" name="fg" 
                                                            @change="fgChange"> 
                                                 <p></p> <span>mg/dL</span> 
                                             </label> 
                                         </div> 
                                     </label> 
                                 </div> 
                             </div> 
                             <div class="group"> 
                                 <span>糖化血色素(HbA1c)</span> 
                                 <div class="groupLabel"> 
                                     <label> 
                                         <van-field v-model="examineList[4].ve" placeholder="請填寫" 
                                                    @blur="hb1ac(examineList[4].ve)" type="number"/> 
                                         <div class="unit">
                                             <label style="margin-right: 0.32rem;"> <input v-model="examineList[4].ut"
                                                                                           value="B" type="radio"
                                                                                           name="hba1c"
                                                                                           @change="hba1cChange">
                                                 <p></p> <span>mmol/L</span>
                                             </label>
                                             <label> <input v-model="examineList[4].ut" value="A"
                                                            type="radio" name="hba1c"
                                                            @change="hba1cChange">
                                                 <p></p> <span>%</span>
                                             </label>
                                         </div>
                                     </label> 
                                 </div> 
                             </div> 
                         </div> 
                         <div class="nextQues" @click="nextQuest(17)">填好了</div> 
                         <div class="prevQues" @click="prevQuest"></div> 
                     </div> 
                 </div> 
             </van-swipe-item> 
             <van-swipe-item v-if="blt == 2"> 
                 <div class="swipeBox"ref="scrollRef17"> 
                     <div class="swiperScroll"  > 
                         <div class="question"> 
                             <div class="avatar"></div> 
                             <div class="quesText"> 
                                 請填寫您的血脂肪檢查结果 
                             </div> 
                             <div class="clear"></div> 
                         </div> 
                         <div class="reply"> 
                             <div class="group"> 
                                 <span>總膽固醇(CHOL)</span> 
                                 <div class="groupLabel"> 
                                     <label> 
                                         <van-field v-model="examineList[5].ve" placeholder="請填寫" 
                                                    @blur="chol(examineList[5].ve)" type="number"/> 
                                         <div class="unit"> 
                                             <label style="margin-right: 0.32rem;"> <input v-model="examineList[5].ut" 
                                                                                           value="B" type="radio" 
                                                                                           name="chol" 
                                                                                           @change="cholChange"> 
                                                 <p></p><span>mmol/mol</span></label> 
                                             <label> <input v-model="examineList[5].ut" value="A" 
                                                            type="radio" name="chol" 
                                                            @change="cholChange"> 
                                                 <p></p><span>mg/dL</span></label> 
                                         </div> 
                                     </label> 
                                 </div> 
                             </div> 
                             <div class="group"> 
                                 <span>低密度脂蛋白膽固醇(LDLC)</span> 
                                 <div class="groupLabel"> 
                                     <label> 
                                         <van-field v-model="examineList[6].ve" placeholder="請填寫" 
                                                    @blur="ldlc(examineList[6].ve)" type="number"/> 
                                         <div class="unit"> 
                                             <label style="margin-right: 0.32rem;"> <input v-model="examineList[6].ut" 
                                                                                           value="B" type="radio" 
                                                                                           name="ldlc" 
                                                                                           @change="ldlcChange"> 
                                                 <p></p><span>mmol/mol</span></label> 
                                             <label> <input v-model="examineList[6].ut" value="A" 
                                                            type="radio" name="ldlc" 
                                                            @change="ldlcChange"> 
                                                 <p></p><span>mg/dL</span></label> 
                                         </div> 
                                     </label> 
                                 </div> 
                             </div> 
                             <div class="group"> 
                                 <span>高密度脂蛋白膽固醇(HDLC)</span> 
                                 <div class="groupLabel"> 
                                     <label> 
                                         <van-field v-model="examineList[7].ve" placeholder="請填寫" 
                                                    @blur="hdlc(examineList[7].ve)" type="number"/> 
                                         <div class="unit"> 
                                             <label style="margin-right: 0.32rem;"> <input v-model="examineList[7].ut" 
                                                                                           value="B" type="radio" 
                                                                                           name="hdlc" 
                                                                                           @change="hdlcChange"> 
                                                 <p></p><span>mmol/mol</span></label> 
                                             <label> <input v-model="examineList[7].ut" value="A" 
                                                            type="radio" name="hdlc" 
                                                            @change="hdlcChange"> 
                                                 <p></p><span>mg/dL</span></label> 
                                         </div> 
                                     </label> 
                                 </div> 
                             </div> 
                             <div class="group"> 
                                 <span>三酸甘油脂(TG)</span> 
                                 <div class="groupLabel"> 
                                     <label> 
                                         <van-field v-model="examineList[8].ve" placeholder="請填寫" 
                                                    @blur="tg(examineList[8].ve)" type="number"/> 
                                         <div class="unit"> 
                                             <label style="margin-right: 0.32rem;"> <input v-model="examineList[8].ut" 
                                                                                           value="B" type="radio" 
                                                                                           name="tg" 
                                                                                           @change="tgChange"> 
                                                 <p></p><span>mmol/mol</span></label> 
                                             <label> <input v-model="examineList[8].ut" value="A" 
                                                            type="radio" name="tg" 
                                                            @change="tgChange"> 
                                                 <p></p><span>mg/dL</span></label> 
                                         </div> 
                                     </label> 
                                 </div> 
                             </div> 
                         </div> 
                         <div class="nextQues" @click="nextQuest(18)">填好了 
                         </div> 
                         <div class="prevQues" @click="prevQuest"></div> 
                     </div> 
                 </div> 
             </van-swipe-item> 
             <van-swipe-item v-if="blt == 2"> 
                 <div class="swipeBox" ref="scrollRef18"> 
                     <div class="swiperScroll" > 
                         <div class="question"> 
                             <div class="avatar"></div> 
                             <div class="quesText"> 
                                 請填寫您的肝膽功能檢查结果 
                             </div> 
                             <div class="clear"></div> 
                         </div> 
                         <div class="reply"> 
                             <div class="group"> 
                                 <label> 

                                 </label> 
                                 <span>肝指數GOT</span> 
                                 <div class="groupLabel"> 
                                     <label> 
                                         <van-field v-model="examineList[9].ve" placeholder="請填寫" 
                                                    @blur="got(examineList[9].ve)" type="number"/> 
                                         <div class="unit"><span>IU/L(U/L)</span></div> 
                                     </label> 
                                 </div> 
                             </div> 
                             <div class="group"> 
                                 <span>肝指數GPT</span> 
                                 <div class="groupLabel"> 
                                     <label> 
                                         <van-field v-model="examineList[10].ve" placeholder="請填寫" 
                                                    @blur="gpt(examineList[10].ve)" type="number"/> 
                                         <div class="unit"><span>IU/L(U/L)</span></div> 
                                     </label> 
                                 </div> 
                             </div> 
                             <div class="group"> 
                                 <span>堿性磷酸酶(ALP)</span> 
                                 <div class="groupLabel"> 
                                     <label> 
                                         <van-field v-model="examineList[11].ve" placeholder="請填寫" 
                                                    @blur="alp(examineList[11].ve)" type="number"/> 
                                         <div class="unit"><span>IU/L(U/L)</span></div> 
                                     </label> 
                                 </div> 
                             </div> 
                             <div class="group"> 
                                 <span>白蛋白(ALB)</span> 
                                 <div class="groupLabel"> 
                                     <label> 
                                         <van-field v-model="examineList[12].ve" placeholder="請填寫" 
                                                    @blur="alb(examineList[12].ve)" type="number"/> 
                                         <div class="unit"> 
                                             <label style="margin-right: 0.32rem;"> <input v-model="examineList[12].ut" 
                                                                                           value="B" type="radio" 
                                                                                           name="alb" 
                                                                                           @change="albChange"> 
                                                 <p></p><span>mmol/mol</span></label> 
                                             <label> <input v-model="examineList[12].ut" 
                                                            value="A" 
                                                            type="radio" name="alb" 
                                                            @change="albChange"> 
                                                 <p></p><span>mg/dL</span></label> 
                                         </div> 
                                     </label> 
                                 </div> 
                             </div> 
                         </div> 
                         <div class="nextQues" @click="nextQuest(19)">填好了</div> 
                         <div class="prevQues" @click="prevQuest"></div> 
                     </div> 
                 </div> 
             </van-swipe-item> 
             <van-swipe-item v-if="blt == 2"> 
                 <div class="swipeBox" ref="scrollRef19"> 
                     <div class="swiperScroll" > 
                         <div class="question"> 
                             <div class="avatar"></div> 
                             <div class="quesText"> 
                                 請填寫您的腎功能檢查结果 
                             </div> 
                             <div class="clear"></div> 
                         </div> 
                         <div class="reply"> 
                             <div class="group"> 
                                 <span>肌酸酐(Cre)</span> 
                                 <div class="groupLabel"> 
                                     <label> 
                                         <van-field v-model="examineList[13].ve" placeholder="請填寫" 
                                                    @blur="cre(examineList[13].ve)" type="number"/> 
                                         <div class="unit"> 
                                             <label style="margin-right: 0.32rem;"> <input v-model="examineList[13].ut" 
                                                                                           value="A" 
                                                                                           type="radio" name="cre" 
                                                                                           @change="creChange"> 
                                                 <p></p><span>mg/dL</span></label> 
                                             <label> <input v-model="examineList[13].ut" 
                                                            value="B" type="radio" name="cre" 
                                                            @change="creChange"> 
                                                 <p></p><span>μmol/L</span></label> 
                                         </div> 
                                     </label> 
                                 </div> 
                             </div> 
                             <div class="group"> 
                                 <span>尿酸(UA)</span> 
                                 <div class="groupLabel"> 
                                     <label> 
                                         <van-field v-model="examineList[14].ve" placeholder="請填寫" 
                                                    @blur="ua(examineList[14].ve)" type="number"/> 
                                         <div class="unit"> 
                                             <label style="margin-right: 0.32rem;"> <input v-model="examineList[14].ut" 
                                                                                           value="A" 
                                                                                           type="radio" name="ua" 
                                                                                           @change="uaChange"> 
                                                 <p></p><span>mg/dL</span></label> 
                                             <label> <input v-model="examineList[14].ut" 
                                                            value="B" type="radio" name="ua" 
                                                            @change="uaChange"> 
                                                 <p></p><span>μmol/L</span></label> 
                                         </div> 
                                     </label> 
                                 </div> 
                             </div> 
                             <div class="group"> 
                                 <span>腎絲球過濾率(eGFR)</span> 
                                 <div class="groupLabel"> 
                                     <label> 
                                         <van-field v-model="examineList[15].ve" placeholder="請填寫" 
                                                    @blur="egfr(examineList[15].ve)" type="number"/> 
                                         <div class="unit"><span 
                                                 style="line-height: 0.65rem">ml/min/1.73m <sup>2</sup></span></div> 
                                     </label> 
                                 </div> 
                             </div> 
                         </div> 
                         <div class="nextQues" @click="nextQuest(20)">提交問卷</div> 
                         <div class="prevQues" @click="prevQuest"> 
                         </div> 
                     </div> 
                 </div> 
             </van-swipe-item> -->
      <van-swipe-item v-if="packageType != 6">
        <div class="swipeBox" ref="scrollRef20">
          <div class="swiperScroll">
            <div class="question">
              <div class="avatar"></div>
              <div class="quesText">
                您好，為了獲得您的準確的體型信息和生化指標信息，請您預約您的線下體驗：
              </div>
              <div class="clear"></div>
            </div>
            <div class="reply">
              <div class="group" style="margin-top: 0.32rem">
                <div class="group-cell">
                  <span class="group-cell-label">體檢地點</span>
                  <div class="group-cell-input">
                    <div class="groupLabel selArea">
                      <div class="arrow"></div>
                      <van-field
                        readonly
                        clickable
                        :value="bookingArea.text"
                        placeholder="請選擇"
                        @click="showPicker = true"
                      />
                    </div>
                    <div
                      class="group-add"
                      @click="considerAreaShow = true"
                      v-show="!considerAreaShow"
                    >
                      <div class="group-add-icon">+</div>
                      可以考慮的體檢地點
                    </div>
                    <div class="group-add-cell" v-show="considerAreaShow">
                      <div class="groupLabel selArea">
                        <div class="arrow"></div>
                        <van-field
                          readonly
                          clickable
                          :value="bookingArea2.text"
                          placeholder="可以考慮的體檢地點"
                          @click="showPicker2 = true"
                        />
                      </div>
                      <div
                        class="cancel-consider"
                        @click="
                          () => {
                            (considerAreaShow = false), (bookingArea2 = '');
                          }
                        "
                      >
                        <div class="cancel-consider-box">-</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="group">
                <div class="group-cell">
                  <span class="group-cell-label">體檢時間</span>
                  <div class="group-cell-input">
                    <div class="groupLabel">
                      <div class="arrow"></div>
                      <input
                        type="text"
                        style="width: 100%;"
                        readonly="readonly"
                        @click="pickerShow = true"
                        v-model="bookTime"
                        placeholder="請填寫"
                      />
                    </div>
                    <div
                      class="group-add"
                      @click="considerTimeShow = true"
                      v-show="!considerTimeShow"
                    >
                      <div class="group-add-icon">+</div>
                      可以考慮的體檢時間
                    </div>
                    <div class="group-add-cell" v-show="considerTimeShow">
                      <div class="groupLabel selArea">
                        <div class="arrow"></div>
                        <van-field
                          readonly
                          clickable
                          :value="bookTime2"
                          placeholder="可以考慮的體檢時間"
                          @click="pickerShow2 = true"
                        />
                      </div>
                      <div
                        class="cancel-consider"
                        @click="
                          () => {
                            (considerTimeShow = false), (bookTime2 = '');
                          }
                        "
                      >
                        <div class="cancel-consider-box">-</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <p style="font-weight: 500;margin-top: 0.56rem"></p>
              <div class="group">
                <div class="group-cell">
                  <span class="group-cell-label">聯系電話</span>
                  <div class="groupLabel area_s">
                    <div class="arrow"></div>
                    <van-field
                      readonly
                      clickable
                      :value="area_s"
                      placeholder="請選擇"
                      @click="showPickerA = true"
                    />
                  </div>
                  <div class="groupLabel" style="width: 3rem;">
                    <van-field
                      v-model="phone"
                      maxlength="11"
                      placeholder="請填寫"
                      type="number"
                    />
                  </div>
                </div>
                <div class="group-cell margin-top15">
                  <span class="group-cell-label code-text ">驗證碼</span>
                  <div class="group-cell-input">
                    <div class="groupLabel send_cell">
                      <label>
                        <van-field
                          v-model="phoneCode"
                          maxlength="4"
                          placeholder="請填寫驗證碼"
                          type="number"
                        />
                        <van-button
                          slot="button"
                          class="get-code qjc-c-primary sendCodeText"
                          :disabled="isSend"
                          @click="sendCode"
                        >
                          <span v-show="isSend">
                            <van-count-down
                              class="qjc-inline"
                              :time="60 * 1000"
                              format="ss"
                              ref="countDown"
                              :auto-start="false"
                              @finish="finish"
                            />
                            s後重新獲取
                          </span>
                          <span style="color: #6681FA" v-show="!isSend"
                            >獲取驗證碼</span
                          >
                        </van-button>
                        <!-- <span @click="sendCode"
													  :class="{ disabled: sending }"
													  class="sendCodeText"><van-loading v-if="sending" type="spinner" /><span v-else class="ac-default">短信驗證碼</span></span> -->
                      </label>
                    </div>
                    <div class="group-desc fitText">
                      香港體檢會在3個工作日內向您致電，向您確認並完成預約。
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="group group-desc-text fitText">
              1.體檢完成後，我們會自動獲取您的相關體檢結果信息，並完成您的健康大數據體檢報告，而無需您再次輸入。<br />
              2.屆時您將會收到短訊通知，並可回到本平台登錄查看您的健康大數據體檢報告。
            </div>
            <div
              class="nextQues"
              style="width: 4.3rem;"
              :class="{ disabled: submiting }"
              @click="nextQuest(21)"
            >
              <van-loading v-if="submiting" type="spinner" /><span v-else
                >我知道了，進行預約</span
              >
            </div>
            <div class="prevQues" @click="prevQuest">上一步</div>
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>
    <div class="mark" v-show="markShowNull">
      <div>
        <div class="markBox">
          <img src="../../assets/images/errorTip.png" alt="" />
          <div class="tipText">
            您尚有未完成的健康大數據評估，是否繼續完成？
          </div>
          <div class="btnGroup">
            <div class="btnEsc" @click="backHome">返回首頁</div>
            <div class="btnEnt" @click="close">繼續完成</div>
          </div>
          <div style="clear: both;"></div>
        </div>
        <div class="close" @click="close"></div>
      </div>
    </div>
    <div class="mark" v-show="markShowError">
      <div>
        <div class="markBoxError">
          <div class="markBoxErrorImg">
            <img src="../../assets/images/tips.png" alt="" />
          </div>
          <div class="tipText2 tipText3" v-html="errorTip"></div>
          <div class="btnGroup">
            <div class="btnEnt2 qjc-fts-32" @click="close">我知道了</div>
          </div>
        </div>
        <div class="close" @click="close"></div>
      </div>
    </div>

    <!-- 性别 -->
    <van-popup v-model="sexSel" position="bottom">
      <van-picker
        :show-toolbar="true"
        :columns="sexList"
        @cancel="sexSel = false"
        @confirm="sexConfirm"
        class="sexPicker"
      />
    </van-popup>
    <!-- 生日 -->
    <van-popup v-model="birthSel" position="bottom">
      <van-datetime-picker
        type="date"
        :formatter="formatter"
        v-model="currentBirth"
        :min-date="birthMinDate"
        :max-date="birthMaxDate"
        @cancel="birthSel = false"
        @confirm="birthConfirm"
        class="birthPicker"
      />
    </van-popup>
    <!-- 预约体检时间 -->
    <van-popup v-model="pickerShow" position="bottom">
      <!-- <div class="dateName">
                <ul>
                    <li>年</li>
                    <li>月</li>
                    <li>日</li>
                    <li>時</li>
                    <li>分</li>
                </ul>
            </div> -->
      <van-datetime-picker
        v-model="currentTime"
        type="date"
        :formatter="formatter"
        :min-date="minDate"
        :max-date="maxDate"
        confirm-button-text="確定"
        cancel-button-text="取消"
        @cancel="pickerShow = false"
        @confirm="confirm"
      />

      <!-- :min-hour="9"
			:max-hour="12" -->

      <!-- :filter="filter" -->
    </van-popup>
    <van-popup v-model="pickerShow2" position="bottom">
      <!-- <div class="dateName">
                <ul>
                    <li>年</li>
                    <li>月</li>
                    <li>日</li>
                   <li>時</li>
                    <li>分</li>
                </ul>
            </div> -->
      <van-datetime-picker
        v-model="currentTime2"
        type="date"
        :formatter="formatter"
        :min-date="minDate"
        :max-date="maxDate"
        confirm-button-text="確定"
        cancel-button-text="取消"
        @cancel="pickerShow2 = false"
        @confirm="confirm2"
      />

      <!-- :min-hour="9"
			:max-hour="12" -->
      <!-- :filter="filter" -->
    </van-popup>

    <!-- 预约 手机区号 -->
    <van-popup v-model="showPickerA" position="bottom">
      <van-picker
        show-toolbar
        :columns="columnsA"
        @cancel="showPickerA = false"
        confirm-button-text="確定"
        cancel-button-text="取消"
        @confirm="onConfirmA"
      />
    </van-popup>

    <!-- 体检地址1 -->
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @cancel="showPicker = false"
        confirm-button-text="確定"
        cancel-button-text="取消"
        @confirm="onConfirm"
      />
    </van-popup>
    <!-- 体检地址2 -->
    <van-popup v-model="showPicker2" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @cancel="showPicker2 = false"
        confirm-button-text="確定"
        cancel-button-text="取消"
        @confirm="onConfirm2"
      />
    </van-popup>
    <!-- 居住地 -->
    <van-popup v-model="showPicker3" position="bottom">
      <van-picker
        show-toolbar
        :columns="areaList"
        @cancel="showPicker3 = false"
        confirm-button-text="確定"
        cancel-button-text="取消"
        @confirm="onConfirm3"
      />
    </van-popup>

    <van-popup class="wait_popup" v-model="showPopover">
      <div class="size_chart" v-if="sex == '男'">
        <div class="size_chart_title">男士尺码表</div>
        <div class="size_chart_tab">
          <div class="size_chart_tab_cell">
            <div class="the no_bottom">尺码(英寸)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item no_bottom">28</div>
              <div class="tab_cell_item no_bottom">29</div>
              <div class="tab_cell_item no_bottom">30</div>
              <div class="tab_cell_item no_bottom">31</div>
              <div class="tab_cell_item no_bottom">32</div>
              <div class="tab_cell_item no_bottom">33</div>
            </div>
          </div>
          <div class="size_chart_tab_cell">
            <div class="the">腰围(cm)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item">70</div>
              <div class="tab_cell_item">74</div>
              <div class="tab_cell_item">78</div>
              <div class="tab_cell_item">80</div>
              <div class="tab_cell_item">84</div>
              <div class="tab_cell_item">88</div>
            </div>
          </div>
        </div>
        <div class="size_chart_tab">
          <div class="size_chart_tab_cell">
            <div class="the no_bottom">尺码(英寸)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item no_bottom">34</div>
              <div class="tab_cell_item no_bottom">36</div>
              <div class="tab_cell_item no_bottom">38</div>
              <div class="tab_cell_item no_bottom">40</div>
              <div class="tab_cell_item no_bottom">42</div>
              <div class="tab_cell_item no_bottom">44</div>
            </div>
          </div>
          <div class="size_chart_tab_cell">
            <div class="the">腰围(cm)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item">90</div>
              <div class="tab_cell_item">94</div>
              <div class="tab_cell_item">98</div>
              <div class="tab_cell_item">100</div>
              <div class="tab_cell_item">104</div>
              <div class="tab_cell_item">106</div>
            </div>
          </div>
        </div>
      </div>
      <div class="size_chart" v-if="sex == '女'">
        <div class="size_chart_title">女士尺码表</div>
        <div class="size_chart_tab">
          <div class="size_chart_tab_cell">
            <div class="the no_bottom">尺码(英寸)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item no_bottom">25</div>
              <div class="tab_cell_item no_bottom">26</div>
              <div class="tab_cell_item no_bottom">27</div>
              <div class="tab_cell_item no_bottom">28</div>
              <div class="tab_cell_item no_bottom">29</div>
              <div class="tab_cell_item no_bottom">30</div>
            </div>
          </div>
          <div class="size_chart_tab_cell">
            <div class="the">腰围(cm)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item">60</div>
              <div class="tab_cell_item">64</div>
              <div class="tab_cell_item">68</div>
              <div class="tab_cell_item">70</div>
              <div class="tab_cell_item">74</div>
              <div class="tab_cell_item">77</div>
            </div>
          </div>
        </div>
        <div class="size_chart_tab">
          <div class="size_chart_tab_cell">
            <div class="the no_bottom">尺码(英寸)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item no_bottom">31</div>
              <div class="tab_cell_item no_bottom">32</div>
              <div class="tab_cell_item no_bottom">33</div>
              <div class="tab_cell_item no_bottom">34</div>
              <div class="tab_cell_item no_bottom">35</div>
              <div class="tab_cell_item no_bottom">36</div>
            </div>
          </div>
          <div class="size_chart_tab_cell">
            <div class="the">腰围(cm)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item">80</div>
              <div class="tab_cell_item">84</div>
              <div class="tab_cell_item">87</div>
              <div class="tab_cell_item">90</div>
              <div class="tab_cell_item">94</div>
              <div class="tab_cell_item">97</div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>

    <van-popup class="wait_popup" v-model="showPopoverHip">
      <div class="size_chart" v-if="sex == '男'">
        <div class="size_chart_title">男士尺码表</div>
        <div class="size_chart_tab">
          <div class="size_chart_tab_cell">
            <div class="the no_bottom">尺码(英寸)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item no_bottom">28</div>
              <div class="tab_cell_item no_bottom">29</div>
              <div class="tab_cell_item no_bottom">30</div>
              <div class="tab_cell_item no_bottom">31</div>
              <div class="tab_cell_item no_bottom">32</div>
              <div class="tab_cell_item no_bottom">33</div>
            </div>
          </div>
          <div class="size_chart_tab_cell">
            <div class="the">臀围(cm)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item">99</div>
              <div class="tab_cell_item">101</div>
              <div class="tab_cell_item">103</div>
              <div class="tab_cell_item">105</div>
              <div class="tab_cell_item">107</div>
              <div class="tab_cell_item">109</div>
            </div>
          </div>
        </div>
        <div class="size_chart_tab">
          <div class="size_chart_tab_cell">
            <div class="the no_bottom">尺码(英寸)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item no_bottom">34</div>
              <div class="tab_cell_item no_bottom">36</div>
              <div class="tab_cell_item no_bottom">38</div>
              <div class="tab_cell_item no_bottom">40</div>
              <div class="tab_cell_item no_bottom">42</div>
              <div class="tab_cell_item no_bottom">44</div>
            </div>
          </div>
          <div class="size_chart_tab_cell">
            <div class="the">臀围(cm)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item">111</div>
              <div class="tab_cell_item">113</div>
              <div class="tab_cell_item">116</div>
              <div class="tab_cell_item">119</div>
              <div class="tab_cell_item">121</div>
              <div class="tab_cell_item">125</div>
            </div>
          </div>
        </div>
      </div>
      <div class="size_chart" v-if="sex == '女'">
        <div class="size_chart_title">女士尺码表</div>
        <div class="size_chart_tab">
          <div class="size_chart_tab_cell">
            <div class="the no_bottom">尺码(英寸)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item no_bottom">25</div>
              <div class="tab_cell_item no_bottom">26</div>
              <div class="tab_cell_item no_bottom">27</div>
              <div class="tab_cell_item no_bottom">28</div>
              <div class="tab_cell_item no_bottom">29</div>
              <div class="tab_cell_item no_bottom">30</div>
            </div>
          </div>
          <div class="size_chart_tab_cell">
            <div class="the">臀围(cm)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item">80</div>
              <div class="tab_cell_item">84</div>
              <div class="tab_cell_item">87</div>
              <div class="tab_cell_item">90</div>
              <div class="tab_cell_item">94</div>
              <div class="tab_cell_item">97</div>
            </div>
          </div>
        </div>
        <div class="size_chart_tab">
          <div class="size_chart_tab_cell">
            <div class="the no_bottom">尺码(英寸)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item no_bottom">31</div>
              <div class="tab_cell_item no_bottom">32</div>
              <div class="tab_cell_item no_bottom">33</div>
              <div class="tab_cell_item no_bottom">34</div>
              <div class="tab_cell_item no_bottom">35</div>
              <div class="tab_cell_item no_bottom">36</div>
            </div>
          </div>
          <div class="size_chart_tab_cell">
            <div class="the">臀围(cm)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item">103</div>
              <div class="tab_cell_item">107</div>
              <div class="tab_cell_item">110</div>
              <div class="tab_cell_item">113</div>
              <div class="tab_cell_item">120</div>
              <div class="tab_cell_item">127</div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>

    <!-- 测量标准 -->
    <van-popup v-model="stand" class="stand">
      <van-image
        width="4.36rem"
        height="4.37rem"
        :src="require('@/assets/images/questions_measureStand.png')"
      ></van-image>
      <ul class="qjc-c-dark qjc-fts-28 qjc-texta-l">
        <li>
        	胸圍：平靜呼吸時，經乳頭點的胸部水平圍長。
        </li>
        <li>
        	腰圍：經臍部中心的水平圍長，或肋骨以下、胯骨以上的最小水平圍長，在呼氣結束、吸氣未開始時測量。
        </li>
        <li>
        	臀圍：臀部向後最突出部位的水平圍長。
        </li>
      </ul>
      <!-- 关闭按钮 -->
      <van-image
        width="0.8rem"
        height="0.8rem"
        class="close-btn"
        @click="stand = false"
        :src="require('@/assets/images/givingMasks_close.png')"
      ></van-image>
    </van-popup>
  </div>
</template>

<script>
import question1 from "../../js/question1";
import Vue from "vue";
import {
  NavBar,
  Progress,
  Swipe,
  SwipeItem,
  RadioGroup,
  Radio,
  Checkbox,
  CheckboxGroup,
  Field,
  Picker,
  Popup,
  Area,
  DatetimePicker,
  Cell,
  CellGroup,
  CountDown,
  Loading,
} from "vant";
import Login from "../login/index";

Vue.use(NavBar)
  .use(Progress)
  .use(CountDown)
  .use(Swipe)
  .use(SwipeItem)
  .use(RadioGroup)
  .use(Radio)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Field)
  .use(Picker)
  .use(Popup)
  .use(Area)
  .use(DatetimePicker)
  .use(Cell)
  .use(CellGroup)
  .use(Loading);
export default {
  ...question1,
};
</script>

<style src="../../assets/css/quesstion.css" scoped></style>
<style scoped>
.content >>> .van-icon-arrow-left:before {
  color: #40444d;
}

.content >>> .van-nav-bar__title {
  font-size: 0.36rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
}

.content >>> .van-progress {
  margin-top: 46px;
}

.content >>> .van-radio {
  padding: 0.4rem 0;
  border-bottom: 0.01rem solid #eeeeee;
}

.content >>> .van-radio:last-child {
  border: none;
}

.content >>> .van-checkbox {
  padding: 0.4rem 0;
  border-bottom: 0.01rem solid #eeeeee;
}

.content >>> .van-checkbox__icon .van-icon {
  border-radius: 4px;
}

.content >>> .van-swipe__indicators {
  bottom: -12px;
}

.content >>> .van-cell {
  padding: 0;
  float: left;
  width: 100%;
  height: 0.7rem;
}

.content >>> .van-cell input {
  float: left;
  font-size: 0.32rem;
  height: 0.7rem;
  line-height: 0.7rem;
}

.content >>> .van-cell input::placeholder {
  font-size: 0.32rem;
  font-family: PingFang SC;
  font-weight: 300;
  color: rgba(161, 167, 178, 1);
}

.selArea >>> .van-cell {
  width: 100% !important;
}
.new_question {
  margin: 0rem 0.24rem 1.48rem 0.24rem;
}
.new_question .quesText {
  font-size: 0.28rem;
  line-height: 0.48rem;
  min-width: 5.7rem !important;
}
.new_question .question_tips_icon {
  width: 1.06rem;
  height: 0.32rem;
}

.waist_box .nextQues {
  margin-bottom: 0.5rem !important;
}
.sleep_box .nextQues {
  margin-bottom: 0.5rem !important;
}
</style>

<style lang="scss" scoped>
.group_title_cell {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .cankao {
    color: rgba(140, 145, 154, 1);
    font-family: PingFang SC;
    font-size: 0.28rem;
  }
}

.wait_popup {
  border-radius: 0.12rem;
}

.size_chart {
  width: 6rem;
  height: 3.6rem;
  text-align: center;
  padding: 0.3rem;
  border-radius: 0.12rem;

  .size_chart_title {
    font-size: 0.28rem;
    font-weight: 500;
    font-family: SF Pro Text;
    color: rgb(50, 54, 62);
  }
  .size_chart_tab {
    margin-top: 0.16rem;
  }
  .size_chart_tab_cell {
    display: flex;
    align-items: center;
    .tab_cell_item_list {
      flex: 1;
      display: flex;
      align-items: center;
    }
    .tab_cell_item {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 0.6rem;
      height: 0.48rem;
      // padding: 0 0.10rem;
      border-top: 0.01rem solid rgb(215, 219, 225);
      border-bottom: 0.01rem solid rgb(215, 219, 225);
      border-right: 0.01rem solid rgb(215, 219, 225);
      box-sizing: border-box;
    }
    .the {
      display: flex;
      align-items: center;
      width: 1.6rem;
      text-align: left;
      height: 0.48rem;
      padding: 0 0.1rem;
      border: 0.01rem solid rgb(215, 219, 225);
    }
    .no_bottom {
      border-bottom: none !important;
    }
  }
}
.page_box {
  position: absolute;
  top: 56px;
  right: 0.18rem;
  width: 1.2rem;
  font-size: 0.3rem;
  padding: 0.08rem 0.12rem;
  border: 0.01rem solid rgba(102, 129, 250, 1);
  border-radius: 0.4rem;
  z-index: 999;
  font-weight: 500;

  span {
    font-size: 0.36rem;
    color: rgba(102, 129, 250, 1);
  }
}
.err_tip {
  color: #6681fa;
}

.area_s {
  width: 1.6rem;
  padding: 0 0.2rem;
}

.get-code {
  height: 0.7rem;
  border-radius: 0 0.16rem 0.16rem 0;
  padding: 0 0.2rem;
  display: flex;
  align-items: center;
}
.send_cell {
  padding: 0.02rem 0 0.02rem 0.25rem;
}
</style>
